import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';

import Checkout from 'components/checkout';
import PurchaseStatusPage from 'components/purchase-status';

import { getCenterName } from 'utils/location';

import useSetCenterCookie from 'hooks/use-set-center-cookie';

const CheckoutPage = (props) => {
  const [centerId, setCenterId] = useState('');
  const centerName = getCenterName(props.location);

  useEffect(() => {
    setCenterId(`centre-${centerName}`);
  }, []);

  useSetCenterCookie(centerId);

  if (!centerId) {
    return null;
  }

  return (
    <Router path="/">
      <Checkout centerId={centerId} path="/checkout" />
      <PurchaseStatusPage centerId={centerId} location={props.location} path="/checkout/status" />
    </Router>
  );
};

export default CheckoutPage;
