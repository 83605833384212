import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';

import { getCenterById } from 'utils/getCentersBy';
import userIsLoggedIn from 'utils/checkLoggedIn';

import CartDetails from './CartDetails';
import LoginForm from './steps/CheckoutLoginForm';
import PaymentForm from './steps/PaymentForm';
import PaymentSelection from './steps/PaymentSelection/View';
import GiftForm from './steps/GiftForm';

import './checkout.scss';

const CheckoutView = (props) => {

  
  switch (props.viewShown) {
    case 'login':
      return <LoginForm setViewShown={props.setViewShown} selectedCenter={props.selectedCenter} giftProducts={props.giftProducts} />;
    case 'paymentSelection':
      return (
        <PaymentSelection
          selectedCenter={props.selectedCenter}
          setViewShown={props.setViewShown}
          cartData={props.cartData}
          setCart={props.setCart}
        />
      );
    case 'payment':
      return <PaymentForm selectedCenter={props.selectedCenter} />;
    case 'gift':
      return (
        <GiftForm
          selectedCenter={props.selectedCenter}
          setViewShown={props.setViewShown}
          products={props.products}
        />
      );
    default:
      return null;
  }
};

const Checkout = (props) => {


  //console.log(props);
  
  const {
    location, cartData, centers, centerId, giftProducts, coffretProducts, actions: {
      setCart,
    },
  } = props;

  const [selectedCenter, setSelectedCenter] = useState(null);
  const [viewShown, setViewShown] = useState('login');

  useEffect(() => {
    setSelectedCenter(getCenterById(centers, centerId));
  }, []);

  useEffect(() => {
    if (!cartData.Products.length) {
      navigate('/panier');
    }
  }, [cartData, selectedCenter]);

  useEffect(() => {
    if (userIsLoggedIn(centerId)) {
      if (giftProducts.length > 0) {
        setViewShown('gift');
      } else {
        setViewShown('paymentSelection');
      }
    }
  }, [centerId]);

  if (!cartData.Products.length) {
    return null;
  }

  return (
    <Layout location={location}>
      <SEO title="Checkout" />
      <div className="container">
        <div className="checkout--wrapper">
          <CheckoutView
            setViewShown={setViewShown}
            viewShown={viewShown}
            selectedCenter={selectedCenter}
            products={cartData.Products}
            cartData={cartData}
            setCart={setCart}
            giftProducts={giftProducts}
          />
          <CartDetails setViewShown={setViewShown} selectedCenter={selectedCenter} cartData={cartData} setCart={setCart} />
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
