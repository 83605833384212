import React, { useState, useEffect } from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { Form, Input, Radio, Checkbox, DatePicker, Select, Switch } from 'antd';
import API from 'api';
import useWindowWidth from 'hooks/use-window-width';

const CoffretForm = (props) => {
  const { giftProduct, submitForm, handDelivery } = props;
  const [showAdditionalMessage, setShowAdditionalMessage] = useState('Show');
  
  const [zipOptions, setZipOptions] = useState([]);
  const [zipOptionsFetching, setZipOptionsFetching] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [checked, setChecked] = useState(handDelivery);

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });


  useEffect(() => {
    setChecked(handDelivery);
  }, [handDelivery]);
  
  const [ formN ] = Form.useForm();

  useEffect(() => {
    formN.setFieldsValue({ handDelivery:checked });
    //console.log(checked);
  }, [checked]);
  
  const findZipcodeOid = (val) => {
    setZipOptionsFetching(true);
    API.get(`/zipcode/search/${val}`).then((res) => {
      setZipOptionsFetching(false);
      setZipOptions(res.data.responseData);
    });
  };

  const debouncedFindZipcodeOid = debounce(findZipcodeOid, 200);

  const changeZipcode = (e) => {
    if (e.target.value) {
      if(e.target.value.length === 5) {
        debouncedFindZipcodeOid(e.target.value);
      }
    }
  };

  return (
    <>
    {(windowWidth < 768) ? (
      <Form name={`${giftProduct.uid}`} form={formN} initialValues={{ handDelivery: handDelivery }}  layout="vertical" className="gift-form">
        <div className="gift-form-row">
          <div className="gift-form-column">
            <Form.Item
              name="offreur"
              label="De la part de"
              validateTrigger={['onBlur', 'onSubmit']}
              rules={
              [
                { required: true, message: 'Ce champ est requis' },
              ]
              }
            >
            <Input />
              </Form.Item>
              <Form.Item
              name="lastName"
              label="Nom du bénéficiaire"
              validateTrigger={['onBlur', 'onSubmit']}
              rules={[{ required: true, message: 'Ce champ est requis' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="firstName"
              label="Prénom du bénéficiaire"
              validateTrigger={['onBlur', 'onSubmit']}
              rules={[{ required: true, message: 'Ce champ est requis' }]}
            >
              <Input />
            </Form.Item>
            {!handDelivery ? (
              <Form.Item
                name="recipient_email"
                label="Email du bénéficiaire"
                rules={
                [
                  { type: 'email', message: 'L\'entrée n\'est pas un e-mail valide' },
                ]
                }
              >
                <Input />
              </Form.Item>
            ) : null}

            
          {!handDelivery ? (
            <Form.Item
            name="date"
            label="Date d'envoi"
            validateTrigger={['onBlur', 'onSubmit']}
            rules={[{ required: true, message: 'Ce champ est requis' }]}
          >
            <DatePicker style={{ width: '100%' }} locale={locale} format="DD/MM/YYYY" placeholder="Choisir une date" />
          </Form.Item>
          ) : null}
          </div>
        </div>
        <div className="gift-form-row">
          <p class="mt-4 titre_cadeau">Saisir les coordonnées pour l'expédition de votre cadeau :</p>
        </div>
        <div className="gift-form-row">
        <div className="gift-form-column">
          <Form.Item
          name="sender_lastName"
          label="Nom"
          validateTrigger={['onBlur', 'onSubmit']}
          rules={
          [
            { required: true, message: 'Ce champ est requis' },
          ]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sender_firstname"
          label="Prénom"
          validateTrigger={['onBlur', 'onSubmit']}
          rules={[{ required: true, message: 'Ce champ est requis' }]}
          >
          <Input />
          </Form.Item>
        <Form.Item
          name="address"
          label="Adresse d'expédition"
          validateTrigger={['onBlur', 'onSubmit']}
          rules={
          [
            { required: true, message: 'Ce champ est requis' },
          ]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
        name="code_postal"
        label="Code postal"
        onChange={changeZipcode}
        validateTrigger={['onBlur', 'onSubmit']}
        rules={[{ required: true, message: 'Ce champ est requis' }]}
        >
        <Input />
        </Form.Item>
        
        
        </div>
        <div className="gift-form-column">
          
        
        <Form.Item
        name="address_comp"
        label="Complément d'adresse"
        validateTrigger={['onBlur', 'onSubmit']}
      >
        <Input />
      </Form.Item>
      <Form.Item
      name="ville"
      label="Ville"
      className="registration-form-field"
      rules={[{ required: true, message: 'Veuillez choisir votre ville' }]}
    >
      <Select
        loading={zipOptionsFetching}
        showSearch
        disabled={zipOptionsFetching || !zipOptions?.length}
        placeholder=""
        filterOption={
          (input, option) => option.children.toLowerCase().includes(
            input.toLowerCase(),
          )
        }
      >
        {zipOptions?.map((option) => (
          <Option key={`${option.oid}`} value={option.commune.nomMaj}>
            {option.commune.nomMaj}
          </Option>
        ))}
      </Select>
    </Form.Item>
      
        
        </div>
        </div>
        

        <p className="mt-4 titre_cadeau">Personnaliser votre cadeau :</p>

        <Form.Item>
          <Radio checked={showAdditionalMessage === 'Show'} value="Show" onClick={() => setShowAdditionalMessage('Show')}>Ajouter un message personnalisé</Radio>
          <Radio checked={showAdditionalMessage === 'Hide'} value="Hide" onClick={() => setShowAdditionalMessage('Hide')}>Non merci</Radio>
        </Form.Item>
        <Form.Item name="message">
          {showAdditionalMessage === 'Show' ? (
            <>
              <b>ATTENTION : les émoticônes ne sont pas acceptés et le nombre de caractères est limité à 255.</b>
              <Form.Item name="message">
                <Input.TextArea autoSize maxLength={255}  />
              </Form.Item>
            </>
          ) : null}
        </Form.Item>
        <Form.Item>
        <div className="change-pass-buttons--wrapper">
          <button id={`${giftProduct.uid}-submit`} type="submit" className="primary-caliceo--button d-none">
          Modifier
          </button>
        </div>
        </Form.Item>
          <Form.Item noStyle name="handDelivery" hidden className="d-none">
            <Switch
            checked={checked} onChange={setChecked}
            checkedChildren="On"
            unCheckedChildren="Off"
            className="d-none"
            />
        </Form.Item>
      </Form>
    ) : (
      <Form name={`${giftProduct.uid}`} form={formN} initialValues={{ handDelivery: handDelivery }}  layout="vertical" className="gift-form">
    
    
      
    
    <div className="gift-form-row">
    <div className="gift-form-column">
      <Form.Item
      name="offreur"
      label="De la part de"
      validateTrigger={['onBlur', 'onSubmit']}
      rules={
      [
        { required: true, message: 'Ce champ est requis' },
      ]
      }
    >
    <Input />
      </Form.Item>
      <Form.Item
      name="lastName"
      label="Nom du bénéficiaire"
      validateTrigger={['onBlur', 'onSubmit']}
      rules={[{ required: true, message: 'Ce champ est requis' }]}
    >
      <Input />
    </Form.Item>
    {!handDelivery ? (
      <Form.Item
        name="recipient_email"
        label="Email du bénéficiaire"
        rules={
        [
          { type: 'email', message: 'L\'entrée n\'est pas un e-mail valide' },
        ]
        }
      >
        <Input />
      </Form.Item>
    ) : null}
    
    </div>
    <div className="gift-form-column">
      <div className="gift-form-empty" />
      <Form.Item
      name="firstName"
      label="Prénom du bénéficiaire"
      validateTrigger={['onBlur', 'onSubmit']}
      rules={[{ required: true, message: 'Ce champ est requis' }]}
    >
      <Input />
    </Form.Item>
    
  {!handDelivery ? (
    <Form.Item
    name="date"
    label="Date d'envoi"
    validateTrigger={['onBlur', 'onSubmit']}
    rules={[{ required: true, message: 'Ce champ est requis' }]}
  >
    <DatePicker style={{ width: '100%' }} locale={locale} format="DD/MM/YYYY" placeholder="Choisir une date" />
  </Form.Item>
  ) : null}
    </div>
    </div>
    <div className="gift-form-row">
      <p class="mt-4 titre_cadeau">Saisir les coordonnées pour l'expédition de votre cadeau :</p>
    </div>
    <div className="gift-form-row">
    <div className="gift-form-column">
      <Form.Item
      name="sender_lastName"
      label="Nom"
      validateTrigger={['onBlur', 'onSubmit']}
      rules={
      [
        { required: true, message: 'Ce champ est requis' },
      ]
      }
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="address"
      label="Adresse d'expédition"
      validateTrigger={['onBlur', 'onSubmit']}
      rules={
      [
        { required: true, message: 'Ce champ est requis' },
      ]
      }
    >
      <Input />
    </Form.Item>
    <Form.Item
    name="code_postal"
    label="Code postal"
    onChange={changeZipcode}
    validateTrigger={['onBlur', 'onSubmit']}
    rules={[{ required: true, message: 'Ce champ est requis' }]}
    >
    <Input />
    </Form.Item>
    
    
    </div>
    <div className="gift-form-column">
      
    <Form.Item
    name="sender_firstname"
    label="Prénom"
    validateTrigger={['onBlur', 'onSubmit']}
    rules={[{ required: true, message: 'Ce champ est requis' }]}
    >
    <Input />
    </Form.Item>
    <Form.Item
    name="address_comp"
    label="Complément d'adresse"
    validateTrigger={['onBlur', 'onSubmit']}
  >
    <Input />
  </Form.Item>
  <Form.Item
  name="ville"
  label="Ville"
  className="registration-form-field"
  rules={[{ required: true, message: 'Veuillez choisir votre ville' }]}
>
  <Select
    loading={zipOptionsFetching}
    showSearch
    disabled={zipOptionsFetching || !zipOptions?.length}
    placeholder=""
    filterOption={
      (input, option) => option.children.toLowerCase().includes(
        input.toLowerCase(),
      )
    }
  >
    {zipOptions?.map((option) => (
      <Option key={`${option.oid}`} value={option.commune.nomMaj}>
        {option.commune.nomMaj}
      </Option>
    ))}
  </Select>
</Form.Item>
  
    
    </div>
    </div>
    

    <p className="mt-4 titre_cadeau">Personnaliser votre cadeau :</p>

    <Form.Item>
      <Radio checked={showAdditionalMessage === 'Show'} value="Show" onClick={() => setShowAdditionalMessage('Show')}>Ajouter un message personnalisé</Radio>
      <Radio checked={showAdditionalMessage === 'Hide'} value="Hide" onClick={() => setShowAdditionalMessage('Hide')}>Non merci</Radio>
    </Form.Item>
    <Form.Item name="message">
      {showAdditionalMessage === 'Show' ? (
        <>
          <b>ATTENTION : les émoticônes ne sont pas acceptés et le nombre de caractères est limité à 255.</b>
          <Form.Item name="message">
            <Input.TextArea autoSize maxLength={255}  />
          </Form.Item>
        </>
      ) : null}
    </Form.Item>
    <Form.Item>
    <div className="change-pass-buttons--wrapper">
      <button id={`${giftProduct.uid}-submit`} type="submit" className="primary-caliceo--button d-none">
      Modifier
      </button>
    </div>
    </Form.Item>
      <Form.Item noStyle name="handDelivery" hidden className="d-none">
        <Switch
        checked={checked} onChange={setChecked}
        checkedChildren="On"
        unCheckedChildren="Off"
        className="d-none"
        />
    </Form.Item>
  </Form>
    )}
  </>
  );

};

export default CoffretForm;
