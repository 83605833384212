import React from 'react';

import CaliceoLink from 'components/buttons/CaliceoButton';

const FailureOrder = () => (
  <div>
    <p className="order-status--order-number">Votre paiement a échoué</p>
    <p className="order-status-text">
      Vous pouvez retenter un nouveau paiement en cliquant sur le bouton
      ci-dessous ou en contactant directement votre centre.
    </p>
    <CaliceoLink
      title="Renouveler mon paiement"
      url="/checkout"
      classNames="mx-auto mt-5"
      primaryButton
    />
  </div>
);

export default FailureOrder;
