import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectGiftProducts, selectCoffretProducts } from 'redux/selectors/cart';
import { setCart, removeAllGiftItems, setProductAsCoffret, removeProductAsCoffret } from 'redux/actions/cart';

const mapStateToProps = (state) => ({
  giftProducts: selectGiftProducts(state),
  coffretProducts: selectCoffretProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setCart,
    removeAllGiftItems,
    setProductAsCoffret,
    removeProductAsCoffret
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
