import React, { useState, useEffect, useCallback } from 'react';
import API from 'api';
import qs from 'qs';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { centerCookieIds } from 'utils/constants';
import { navigate } from 'gatsby';
import {
  Form, Input, Checkbox, Select, notification,
} from 'antd';

import PinIcon from 'components/icon-components/Pin';
import CaliceoButton from 'components/buttons/CaliceoButton';
import 'utils/font-awesome';
import './GiftForm.scss';

import GiftFormItem from './GiftFormItem';

const { Option } = Select;

const cookies = new Cookies();

const GiftForm = (props) => {
  const {
    selectedCenter,
    setViewShown,
    products,
    giftProducts,
    coffretProducts,
    actions: {
      setCart,
      removeAllGiftItems,
      setProductAsCoffret,
      removeProductAsCoffret
    }
  } = props;

  const centerId = props.selectedCenter.centerId;

  const [showGiftForm, setShowGiftForm] = useState(false);
  const [productForms, setProductForms] = useState({});
  const [formsValues, setFormsValues] = useState([]);
  const [newGiftProducts, setNewGiftProducts] = useState(null);
  const [emailAccount, setEmailAccount] = useState("");
  const [orderLoading, setOrderLoading] = useState(false);

  const handleChangeGiftFormCheckbox = (e) => {
    setShowGiftForm(e.target.checked);
  };

  const handleSelectProductsToOffer = (e) => {
   // console.log(e, 'select offer');
  };

  useEffect(() => {
    if (giftProducts.length > 0) {
      setShowGiftForm(true);
    }
  }, [products]);

  /*useEffect(() => {
    if(handDelivery) {
      API.get(`/email?session=${cookies.get(centerCookieIds[props.selectedCenter.centerId]?.cookieName)}`, { centerId }).then((res) => {
        if (res.data.status === 1) {
          setEmailAccount(res.data.responseData.email);
        }
      });
    }
  }, [handDelivery]);*/


  const submitForms = () => {

    let valideform = true;
    giftProducts.forEach((gp) => {

      if (document.getElementById(`${gp.uid}`)) {
        if(document.getElementById(`${gp.uid}_offreur`).value === "") {
          valideform = false;
        }
        const find_p = coffretProducts.find((element) => {
          return element === gp.uid;
        })
        if(!find_p) {
          if(document.getElementById(`${gp.uid}_recipient_email`)?.value === "") {
            valideform = false;
          }
        }
        

        
      }
    });
    if(valideform) {
      removeCart();
      document.getElementById(`${giftProducts[0].uid}-submit`).click();
    }else {
      notification.error({message: "Des champs obligatoires à remplir sont manquants",});
    }
  };

  useEffect(() => {
    const newFormValues = Object.keys(productForms).map((_form) => ({
      [_form]: productForms[_form].getFieldsValue(),
    }));
    const newFormValuesObj = newFormValues.reduce((a, b) => Object.assign(a, b), {});
    setFormsValues(newFormValuesObj);
    API.get(`/email?session=${cookies.get(centerCookieIds[props.selectedCenter.centerId]?.cookieName)}`, { centerId }).then((res) => {
        if (res.data.status === 1) {
          setEmailAccount(res.data.responseData.email);
        }
      });
  }, [productForms]);

  const removeItemFromCart = async (uid) => {
    const values = {
      session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
      pdf:"a99",
      uid,
    };
    return API.post('/uefmfufgspndbs', qs.stringify(values), { centerId: selectedCenter?.centerId });
  };

  const removeCart = async () => {
    const values = {
      session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)
    };
    return API.post('/uefmfufdbs', qs.stringify(values), { centerId: selectedCenter?.centerId });
  };

  const addItemToCart = async (id, qty = 1, recipient = null, datexp = null, envp = null) => {
    const all_val = JSON.parse(recipient);
    const itemData = {
      session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
      quantity: qty,
      variant: id,
      recipient,
      envp,
      datexp,
      gift: true
    };
    return API.post('/ubeeupdbs', qs.stringify(itemData), {
      centerId: selectedCenter?.centerId,
    }).then((res) => {
      if (res.data.status === 1) {
        setCart(res.data.responseData.cartContents);
      }
    });
  };

  const upgradeItemInCart = async (oldProduct, recipientData, dateExp, envp) => {
    await removeItemFromCart(oldProduct.uid);
    await addItemToCart(oldProduct.id, oldProduct.quantity, recipientData, dateExp, envp);
  };

  

  // work in progress, code has bugs
  const updateCartItems = async () => {
    if (Object.keys(formsValues).length > 0) {
      setOrderLoading(true);
      // eslint-disable-next-line no-restricted-syntax
      for (const product of giftProducts) {
        const valuesForProduct = formsValues[product.uid] || formsValues[Object.keys(formsValues)[0]];
        let recipientValues;

        var mess_sender = "";

        if(valuesForProduct.message) {
          mess_sender = valuesForProduct.message.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
          mess_sender = mess_sender.replace(/(?:[\u2600-\u27BF]|(?:\uD83C[\uDF00-\uDFFF])|(?:\uD83D[\uDC00-\uDE4F\uDE80-\uDEFF])|(?:\uD83E[\uDD00-\uDDFF]))/g, '');
        }

        

        if (valuesForProduct.hasOwnProperty('ville')) {
          if(valuesForProduct.handDelivery) {
            recipientValues = {
              fullname: `${valuesForProduct.firstName} ${valuesForProduct.lastName}`,
              offreur:valuesForProduct.offreur,
              nom_expedition:valuesForProduct.offreur,
              sender: `${valuesForProduct.sender_firstname} ${valuesForProduct.sender_lastName}`,
              message:  mess_sender,
              recipient_email: valuesForProduct.recipient_email,
              email:emailAccount,
              mainpropre:valuesForProduct.handDelivery,
              recipient_address: {
                address: valuesForProduct.address,
                address_comp: valuesForProduct.address_comp,
                postal_code: valuesForProduct.code_postal,
                ville: valuesForProduct.ville,
              },
            };
          }else {
            recipientValues = {
              fullname: `${valuesForProduct.firstName} ${valuesForProduct.lastName}`,
              offreur:valuesForProduct.offreur,
              sender: `${valuesForProduct.sender_firstname} ${valuesForProduct.sender_lastName}`,
              message:  mess_sender,
              recipient_email: valuesForProduct.recipient_email,
              email:emailAccount,
              mainpropre:valuesForProduct.handDelivery,
              recipient_address: {
                address: valuesForProduct.address,
                address_comp: valuesForProduct.address_comp,
                postal_code: valuesForProduct.code_postal,
                ville: valuesForProduct.ville,
              },
            };
          }
          
          const date = moment(valuesForProduct.date).format('DD/MM/YYYY');
          await upgradeItemInCart(product, JSON.stringify(recipientValues), date, true);
        } else {
          recipientValues = {
            fullname: `${valuesForProduct.firstName} ${valuesForProduct.lastName}`,
            offreur:valuesForProduct.offreur,
            message: mess_sender,
            recipient_email: valuesForProduct.recipient_email,
            email:emailAccount,
            mainpropre:valuesForProduct.handDelivery,
          };
          const date = moment(valuesForProduct.date).format('DD/MM/YYYY');
          await upgradeItemInCart(product, JSON.stringify(recipientValues), date, false);
        }
      }
      removeAllGiftItems();
      if(localStorage.getItem('CC') && localStorage.getItem('CC') !== "") {
        const postValues = {
          pcode: localStorage.getItem('CC'),
          session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
          aid: 5,
        };
        const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
        API.get(`/fudpefrspnph?${qstring}`, { centerId: selectedCenter?.centerId }).then((res) => {
          //console.log(res.data.status);
          if (res.data.status === 1) {
            props.actions.setCart(res.data.responseData.cartContents);
          }
        });
      }
      setViewShown('paymentSelection');
    }
  };

  useEffect(() => {
    updateCartItems();
  }, [formsValues]);

  return (
    <div className="checkout-login-form--wrapper">
      <div className="checkout-login-form">
        <div className="cart-center-header-name">
          <PinIcon fill="#414141" />
          {selectedCenter?.name}
        </div>
        <h1 className="checkout-form-title">02. Option cadeau</h1>
        <p style={{ marginBottom: '5px' }}>
          Personnalisez ici le mode de réception de votre cadeau. Vous pouvez opter pour un e-billet ou recevoir votre cadeau au format papier.
        </p>
        <Form.Provider
          onFormFinish={(name, info) => {
            setProductForms(info.forms);
          }}
        >
          {showGiftForm ? (
            <>
              {giftProducts.map((giftProduct) => (
                <GiftFormItem
                  firstGiftItem={giftProducts[0]}
                  firstGiftId={giftProducts[0].uid}
                  giftProduct={giftProduct}
                  coffretProducts={coffretProducts}
                  setProductAsCoffret={setProductAsCoffret}
                  removeProductAsCoffret={removeProductAsCoffret}
                  key={giftProduct.uid}
                />
              ))}
            </>
          ) : null}
          <CaliceoButton showIcon={false} primaryButton type="submit" onClick={submitForms} title="Valider" loading={orderLoading} disabled={orderLoading} />
        </Form.Provider>
      </div>
    </div>
  );
};

export default GiftForm;
