import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import qs from 'qs';
import { Form, Input, notification } from 'antd';
import PinIcon from 'components/icon-components/Pin';
import Cookies from 'universal-cookie';

import API from 'api';
import { centerCookieIds } from 'utils/constants';

import load_gif from '../../../../images/load.gif';

const CheckoutLoginForm = (props) => {
  const { selectedCenter, actions, setViewShown, giftProducts } = props;
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [loadlogin, setLoadlogin] = useState(false);
  const cookies = new Cookies();

  const onSubmit = (values) => {
    const loginValues = {
      ...values,
      session: cookies.get(centerCookieIds[selectedCenter.centerId].cookieName),
    };
    setLoadlogin(true);
    API.post('/otjhoj', qs.stringify(loginValues), { centerId: selectedCenter.centerId }).then((res) => {
      if (res.data.status === 1) {
        setLoadlogin(false);
        cookies.set(
          centerCookieIds[selectedCenter.centerId].cookieName,
          res.data.responseData.userContent.session,
          { path: '/' },
        );
        localStorage.setItem(
          centerCookieIds[selectedCenter.centerId].cookieName,
          res.data.responseData.userContent.session,
        );
        localStorage.setItem('user_center', res.data.responseData.userContent.center);
        actions.setUserLoggedIn(res.data.responseData.userContent);
        console.log(giftProducts);
        if(giftProducts.length > 0) {
          setViewShown('gift');
        }else {
          setViewShown('paymentSelection');
        }
      }
      if (res.data.status === 0) {
        setErrorMessage(res.data.responseData.errorMessage);
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });
  };

  return (
    <div className="checkout-login-form--wrapper">
      <div className="checkout-login-form">
        <div className="cart-center-header-name">
          <PinIcon fill="#414141" />
          {selectedCenter?.name}
        </div>
        <h1 className="checkout-form-title">01. Informations personnelles</h1>
        <div className="cont_log_cart">
        {loadlogin ? (
        <div className="loader-paymet loader_log">
          <div className="cont_load">
            <img src={load_gif} style={{width:50}} />
          </div>
          
        </div>
      ) : null}
        <h3 className="login-form--title">Se connecter</h3>
        <Form layout="vertical" form={form} name="control-hooks" onFinish={onSubmit}>
          <Form.Item
            name="username"
            label="Email"
            validateTrigger="onSubmit"
            rules={
              [
                { required: true, message: 'Le champ email n\'est pas saisi' },
                { type: 'email', message: 'L\'entrée n\'est pas un e-mail valide' },
              ]
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            validateTrigger="onSubmit"
            rules={[{ required: true, message: 'Le champ mot de passe n\'est pas saisi' }]}
          >
            <Input.Password />
          </Form.Item>
          <Link to="/reset-password">Mot de passe oublié?</Link>
          {errorMessage && <p>{errorMessage}</p>}
          <Form.Item>
            <button type="submit" className="primary-caliceo--button mx-auto mt-5">
              Se connecter
            </button>
          </Form.Item>
        </Form>
        </div>
        <button
          type="button"
          onClick={() => navigate('/register', {
            state: { checkout: true },
          })}
          className="navigate-to-register-btn"
        >
          <span className="nouv_cli_1">Nouveau client Calicéo ?</span> <span className="nouv_cli_2">Créez votre compte</span>
        </button>
        
      </div>
    </div>
  );
};

export default CheckoutLoginForm;
