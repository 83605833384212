import React, { useState } from 'react';

import CaliceoButton from 'components/buttons/CaliceoButton';

import { Collapse } from 'antd';

import qs from 'qs';
import API from 'api';

import Cookies from 'universal-cookie';
import { centerCookieIds } from 'utils/constants';
import userIsLoggedIn from 'utils/checkLoggedIn';

import { UpCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import { numberDisplayer } from 'utils/number';
import CartDetailsProduct from './CartDetailsProduct';

import TrashIcon from 'components/icon-components/Trash';

import { notification } from 'antd';

const cookies = new Cookies();

const { Panel } = Collapse;

const CartDetails = (props) => {
  const { cartData, selectedCenter, setCart } = props;
  const [showCoupon, setShowCoupon] = useState(false);
  const [panelTitle, setPanelTitle] = useState('Masquer le détail');
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCodeError, setPromoCodeError] = useState('');

  const handleExpandIcon = (i) => {
    if (i.isActive) {
      setPanelTitle('Masquer le détail');
      return <UpCircleFilled className="fourth-zone--accordion-icon" />;
    }
    setPanelTitle('Afficher le détail');
    return <PlusCircleFilled className="fourth-zone--accordion-icon" />;
  };
  
  const changePromoCodeValue = (e) => {
    setPromoCodeValue(e.target.value);
  };
  
  const submitPromoCode = () => {
    if(userIsLoggedIn(selectedCenter?.centerId)) {
      const postValues = {
        pcode: promoCodeValue,
        session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
        aid: 5,
      };
      const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
      API.get(`/fudpefrspnph?${qstring}`, { centerId: selectedCenter?.centerId }).then((res) => {
        //console.log(res.data.status);
        if (res.data.status === 1) {
          setPromoCodeError('');
          setPromoCodeValue('');
          setCart(res.data.responseData.cartContents);
          localStorage.setItem('CC', code);
          notification.success({
            message: "Votre code promo a bien été enregistré.",
          });
        }
        if (res.data.status === 0) {
          setPromoCodeError('Error');
          setPromoCodeValue('');
        }
        if (res.data.status === 3) {
          setPromoCodeError('Error');
          setPromoCodeValue('');
          notification.error({
            message: "Votre code promo n'est pas valide.",
          });

        }
      });
    }else {
      notification.error({
        message: "Vous devez être connecté pour valider votre code promo.",
      });
    }
  };

const removeCodeCadeauReq = (code) => {
    const postValues = {
      pcode: code,
      session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
      aid: 1,
    };
    const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
    API.get(`/fudpefrspnph?${qstring}`, { centerId: selectedCenter?.centerId }).then((res) => {
      //console.log(res.data.status);
      if (res.data.status === 1) {
        localStorage.setItem('CC', '');
        setCart(res.data.responseData.cartContents);

        notification.success({
          message: "Votre code promo a bien été retiré de votre panier",
        });
      }
    });
  }
  
  //console.log(cartData);
  var totalItems = 0;
  cartData?.Products.map((product) => (
    totalItems = totalItems + product.quantity
  ));
  //console.log(totalItems);

  return (
    <div className="cart-details--wrapper">
      <div className="cart-details-header">
        <h3 className="cart-details-title">Récapitulatif de la commande</h3>
        <p className="cart-details-subtitle">
          Valable uniquement dans le centre de {selectedCenter?.name}
        </p>
      </div>
      <div className="cart-details--body">
        <p className="cart-details-product-count">
          {`(${totalItems} ${totalItems < 2 ? 'Produit' : 'Produits'})`}
        </p>
        <Collapse
          defaultActiveKey="1"
          expandIcon={(i) => handleExpandIcon(i)}
          expandIconPosition="right"
          bordered={false}
          accordion
          className="fourth-zone--accordions cart-details-accordion"
        >
          <Panel header={panelTitle} key="1">
            {cartData?.Products.map((product) => (
              <CartDetailsProduct key={product.uid} product={product} />
            ))}
          </Panel>
        </Collapse>

      </div>
      <div className="cart-details--promo-section">
        <button
          type="button"
          onClick={() => setShowCoupon((couponShown) => !couponShown)}
          className="show-promo--toggle-btn"
        >
          Vous avez un code promo ?
        </button>
        {showCoupon && (
          <div className="promo-section-input--wrapper">
            <input className="promo-section-input" value={promoCodeValue} onChange={changePromoCodeValue} type="text" />
            <CaliceoButton
              title=""
              primaryButton
              onClick={submitPromoCode}
              classNames="confirm-promo--btn"
              showIcon
            />
          </div>
        )}
      </div>
      {cartData?.shipping > 0 ? (

        <div className="checkout-section-price">
          Supplément cadeau :
          <span>
            {numberDisplayer(cartData?.shipping)}
            €
          </span>
        </div>
      ) : null}
      {cartData?.codepromo?.remise != 0 ? (
        <>
          <p className="checkout-section-price">
            Montant de la remise
            <span>
              - {cartData.codepromo.remise.toFixed(2)}
              €
            </span>
          </p>
          <div className="liste_code_promo">
                {cartData?.codepromo?.codes?.map((c, i) => {
                  return (
                    <div>
                    <p>{c}</p>
                    <button
                      className="cart-product-remove--button"
                      onClick={() => removeCodeCadeauReq(c)}
                      type="button"
                    >
                      <TrashIcon />
                    </button>
                    </div>
                  );
                })}
                </div>
        </>
      ) : null}
      {cartData?.chequecadeau?.remise != 0 ? (
        <>
          <p className="checkout-section-price">
            Chèque cadeau
            <span>
              - {cartData.chequecadeau.remise.toFixed(2)}
              €
            </span>
          </p>
        </>
      ) : null}
      <div className="checkout-section-price">
        TOTAL TTC :
        <span>
          {numberDisplayer(cartData?.totalTTC.toFixed(2))}
          €
        </span>
      </div>
      <div className="checkout-section-tax">
        dont TVA :
        <span>
          {numberDisplayer(cartData?.totalTVA.toFixed(2))}
          €
        </span>
      </div>
    </div>
  );
};

export default CartDetails;
