import React, { useEffect, useState } from 'react';

import API from 'api';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { getCenterById } from 'utils/getCentersBy';
import OrderStatus from './OrderStatus';

const PurchaseStatus = (props) => {
  const {
    location,
    centerId,
    centers,
    actions,
    paymentSuccessful,
    paymentData,
    paymentLoading,
  } = props;

  const [selectedCenter, setSelectedCenter] = useState(null);

  useEffect(() => {
    setSelectedCenter(getCenterById(centers, centerId));
  }, [centerId, centers]);

  useEffect(() => {
    actions.startPaymentStatus();
  }, []);

  return (
    <Layout location={props.location}>
      <SEO title="Purchase status" />
      <OrderStatus
        selectedCenter={selectedCenter}
        paymentData={paymentData}
        paymentSuccessful={paymentSuccessful}
        paymentLoading={paymentLoading}
      />
    </Layout>
  );
};

export default PurchaseStatus;
