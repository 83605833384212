import React, { useEffect, useState } from 'react';
import PinIcon from 'components/icon-components/Pin';
import Cookies from 'universal-cookie';
import API from 'api';
import qs from 'qs';
import CaliceoButton from 'components/buttons/CaliceoButton'
import CardIcon from 'images/icons/icons8-mastercard-credit-card-80.png';
import TrashIcon from 'components/icon-components/Trash';
import PaypalIcon from 'images/icons/icons8-paypal.svg';


import {
  Form, Input, Radio, Checkbox, DatePicker, Select, notification,
} from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import debounce from 'lodash/debounce';
import { LoadingOutlined } from '@ant-design/icons';
import { centerCookieIds, countriesList } from 'utils/constants';

import load_gif from '../../../../images/load.gif';


import './paymentSection.scss';

const cookies = new Cookies();

const PaymentSelection = (props) => {
  const { selectedCenter, setViewShown, cartData, setCart, userInfo } = props;
  const [showChequeOption, setShowChequeOption] = useState(true);
  const [canPayWithVoucher, setCanPayWithVoucher] = useState(false);
  const [loadPaypal, setLoadPaypal] = useState(false);
  const [hidePaypal, setHidePaypal] = useState(false);
  const [voucherValue, setVoucherValue] = useState('');
  const [vouchers, setVouchers] = useState([]);
  
  
  const [form] = Form.useForm();
  const [zipOptions, setZipOptions] = useState([]);
  const [zipOptionsFetching, setZipOptionsFetching] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [mailUser, setMailUser] = useState("");
  const [phoneUser, setPhoneUser] = useState("");
  const [birthdateUser, setBirthdateUser] = useState("");
  
  const cookies = new Cookies(); 
  

  
  const validateMessages = {
    required: 'Un champ est manquant.',
  };
  
  
  
  const onSubmit = (values) => {
    const registerValues = {
      ...values,
      civility: values.civility,
      
      session: cookies.get(centerCookieIds[selectedCenter.centerId].cookieName),
      address_complement: (values.address_complement == null) ? "not_completed" : values.address_complement,
      
    };
    setRegisterLoading(true);
    API.post('/ssfhjtuf', qs.stringify(registerValues), { centerId: selectedCenter.centerId }).then((res) => {
      if (res.data.status === 1) {
        
        
      } else if (res.data.status === 3) {
        notification.error({
          message: "L'email existe déjà",
        });
        setRegisterLoading(false);
      } else {
        setRegisterLoading(false);
      }
    });
  };
  
  const findZipcodeOid = (val) => {
    setZipOptionsFetching(true);
    API.get(`/zipcode/search/${val}`).then((res) => {
      setZipOptionsFetching(false);
      setZipOptions(res.data.responseData);
      
    });
  };
  
  const debouncedFindZipcodeOid = debounce(findZipcodeOid, 200);
  
  const changeZipcode = (e) => {
    if (e.target.value) {
      if(e.target.value.length === 5) {
        debouncedFindZipcodeOid(e.target.value);
      }
    }
  };
  
  
  
  
  
  

  const handleChangeVoucherValue = (e) => {
    setVoucherValue(e.target.value);
  };


  const submitVoucherValue = () => {
    API.get(`/fudifrvfdbefbvh?session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}&gcode=${voucherValue}&aid=5`).then((res) => {
     // console.log(res.data);
      if (res.data.status === 1) {
        
        var bonCadeauAct = vouchers;
        bonCadeauAct.push(voucherValue);
        setVouchers(bonCadeauAct);
        setVoucherValue("");
        
        setCart(res.data.responseData.cartContents);
        notification.success({
          message: "Votre carte cadeau a bien été utilisée.",
        });
        //localStorage.setItem('webRef', res.data.responseData.webRef);
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
            setVoucherValue("");
          }else {
            notification.error({
              message: "Votre chèque cadeau n'est pas valide.",
            });
            setVoucherValue("");
          }
        }
      }
    });
  };
  
  const removeVoucher = (code) => {
    API.get(`/fudifrvfdbefbvh?session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}&gcode=${code}&aid=1`).then((res) => {
      if (res.data.status === 1) {
        var bonCadeauAct = vouchers;
        
        const index = bonCadeauAct.indexOf(code);
        if (index > -1) {
          bonCadeauAct.splice(index, 1);
        }
        
        setVouchers(bonCadeauAct);
        
        setCart(res.data.responseData.cartContents);
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });
  }

  useEffect(() => {
    setLoadPaypal(true);
    const values = {
      session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
      center: selectedCenter.centerId,
      cid:selectedCenter.centerId,
    };
    
    API.post('/my-account', qs.stringify(values), {
      centerId: selectedCenter.centerId,
    }).then((res) => {
      if (res.data.status === 1) {
        var new_civ = 1;
        if(res.data.responseData.accountContent.civilite === 2) {
          new_civ = 2;
        }else if(res.data.responseData.accountContent.civilite === 4) {
          new_civ = 6;
        }else {
          new_civ = 1;
        }
        //findZipcodeOid(res.data.responseData.accountContent.address?.zipcode);
        
        if (res.data.responseData.accountContent.firstname) {
          setMailUser(res.data.responseData.accountContent.mail);
          setPhoneUser(res.data.responseData.accountContent.phone);
          var n_b = res.data.responseData.accountContent.birthdate[3]+res.data.responseData.accountContent.birthdate[4]+'/'+res.data.responseData.accountContent.birthdate[0]+res.data.responseData.accountContent.birthdate[1]+'/'+res.data.responseData.accountContent.birthdate[6]+res.data.responseData.accountContent.birthdate[7]+res.data.responseData.accountContent.birthdate[8]+res.data.responseData.accountContent.birthdate[9];
          
          setBirthdateUser(n_b);
          form.setFieldsValue({
            civility: `${new_civ}`,
            firstname: res.data.responseData.accountContent.firstname,
            lastname: res.data.responseData.accountContent.lastname,
            
            address: res.data.responseData.accountContent.address?.address,
            zipcode: res.data.responseData.accountContent.address?.zipcode,
            zipid:res.data.responseData.accountContent.address?.zipid,
            zipcode_oid: res.data.responseData.accountContent.address?.municipality,
            
            pays: countriesList.find((country) => country.codeIso2 === res.data.responseData.accountContent.address?.country)?.libelle,
            
          });
          
        }
        setLoadPaypal(false);
      }
    });
    /*API.get(`/udpnnkudbs?session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}`).then((res) => {
      if (res.data.status === 1) {
        console.log(res.data);
        localStorage.setItem('webRef', res.data.responseData.webRef);
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });*/
    window.scrollTo(0, 0);

    if(props.selectedCenter.centerId === "centre-perpignan" || props.selectedCenter.centerId === "centre-saintcyrlecole" || props.selectedCenter.centerId === "centre-lieusaint" || props.selectedCenter.centerId === "centre-lyon" ) {
      setHidePaypal(true);
    }
    setHidePaypal(true);

    cartData?.chequecadeau?.codes.map(c => {
      var bonCadeauAct = vouchers;
      bonCadeauAct.push(c);
      setVouchers(bonCadeauAct);
    });
  }, []);

  useEffect(() => {
    if (cartData) {
      const chequeExists = cartData.Products.some((product) => product.title.includes('Chèque'));

      if (chequeExists) {
        setShowChequeOption(false);
      } else {
        setShowChequeOption(true);
      }
    }
  }, [cartData]);
  
  const requestCBPayment = () => {
    setLoadPaypal(true)
    const fieldE = form.getFieldsValue();
    var err = false;
    
    if(!fieldE.lastname || !fieldE.firstname || !fieldE.zipcode || !fieldE.address || !fieldE.pays) {
      err = true;
    }
    

    var zip_oid = 0;
    
    
    
    if(err) {
      setLoadPaypal(false);
      notification.error({
        message: "Vos informations ne sont pas complètes",
      });
    }else {
      if(fieldE.zipcode) {
        API.get(`/zipcode/search/${fieldE.zipcode}`).then((res) => {
        
          zip_oid = res.data.responseData[0].oid;

          
          const registerValues = {
            lastname:fieldE.lastname,
            firstname:fieldE.firstname,
            zipcode:fieldE.zipcode,
            address:fieldE.address,
            zipcode_oid:zip_oid,
          
            pays:countriesList.find((country) => country.libelle === fieldE.pays).oid,
            civility: fieldE.civility,
            mail:mailUser,
            phone:phoneUser["fix"],
            phone_mobile:phoneUser["mobile"],
            birthdate:birthdateUser,
            interests:null,
            operationnelles_ok:false,
            promotions_ok:false,
            actualites_ok:false,
            rgpd_ok:true,
            sms_ok: true,
            mail_ok: true,
            post_ok: true,
            
            session: cookies.get(centerCookieIds[selectedCenter.centerId].cookieName),
            address_complement: (fieldE.address_complement == null) ? "not_completed" : fieldE.address_complement,
            
          };
          
          //console.log(registerValues);
          API.post('/pejgzbddpvun', qs.stringify(registerValues), { centerId: selectedCenter.centerId }).then((res) => {

            if (res.data.status === 1) {
              API.get(`/udpnnkudbs?session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}&connected_center=${selectedCenter.centerId}&user_center=${localStorage.getItem('user_center')}`).then((res) => {
                setLoadPaypal(false);
                if (res.data.status === 1) {
                  localStorage.setItem('webRef', res.data.responseData.webRef);
                  setViewShown('payment')
                }else {
                  if(res.data.status > 1) {
                    if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
                      notification.error({
                        message: res.data.errorMessage,
                      });
                    }
                  }
                }
                
              });
            }else {
              notification.error({
                message: "Une erreur s'est produite, veuillez contacter un centre",
              });
              setLoadPaypal(false);
            }
          });
        });
      }
      
      
    }
  }

  const requestPaypalPayment = () => {
    setLoadPaypal(true);
    API.get(`/udpnnkudbs?session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}&connected_center=${selectedCenter.centerId}&user_center=${localStorage.getItem('user_center')}`).then((res) => {
      if (res.data.status === 1) {
        localStorage.setItem('webRef', res.data.responseData.webRef);
        const values = {
          session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
          cid: centerCookieIds[selectedCenter?.centerId].cid,
          PaymentCode: localStorage.getItem('webRef'),
        };
        const qstring = Object.keys(values).map((key) => `${key}=${values[key]}`).join('&');
    
        API.get(`/paiement/requestPaypal?${qstring}`).then((res) => {
          setLoadPaypal(false);
          const urlFragment = res.data.split('<');
          window.open(
            urlFragment[0],
            '_self',
          );
        });
      }else {
        if(res.data.status > 1) {
          setLoadPaypal(false);
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });
    
  };

  return (
    <div className="checkout-login-form--wrapper">
      
      <div className="checkout-login-form">
        <div className="cart-center-header-name">
          <PinIcon fill="#414141" />
          {selectedCenter?.name}
        </div>
        <div className="register-form--wrapper container">
          <div className="register-form">
            <h3 className="register-form--title">Mes informations</h3>
            <Form
              initialValues={{ civility: '1' }}
              validateMessages={validateMessages}
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Form.Item className="horizontal-form-row" name="civility" label="Civilité : ">
                <Radio.Group>
                  <Radio value="1">M.</Radio>
                  <Radio value="2">Mme.</Radio>
                  <Radio value="6">Entreprise</Radio>
                </Radio.Group>
              </Form.Item>
              <div className="form-row--wrapper">
                <Form.Item
                  className="registration-form-field"
                  name="lastname"
                  label="Nom"
                  validateTrigger="onSubmit"
                  rules={[{ required: true }]}
                >
                  <Input autoComplete="new-lastname" />
                </Form.Item>
                <Form.Item
                  name="firstname"
                  label="Prénom"
                  className="registration-form-field"
                  validateTrigger="onSubmit"
                  rules={[{ required: true }]}
                >
                  <Input autoComplete="new-firstname" />
                </Form.Item>
              </div>
              
              <div className="form-row--wrapper">
                <Form.Item
                  name="address"
                  label="Adresse"
                  className="registration-form-field"
                  validateTrigger="onSubmit"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address_complement"
                  label="Complément d'adresse"
                  className="registration-form-field"
                  validateTrigger="onSubmit"
                  rules={[{ required: false }]}
                >
                  <Input autoComplete="new-address" />
                </Form.Item>
              </div>
              <div className="form-row--wrapper">
                <Form.Item
                  name="zipcode"
                  className="registration-form-field"
                  label="Code postal"
                  onChange={changeZipcode}
                  validateTrigger="onSubmit"
                  rules={[{ required: true }]}
                >
                  <Input autoComplete="new-zipcode" />
                </Form.Item>
                <Form.Item
                  name="zipcode_oid"
                  label="Ville"
                  className="registration-form-field"
                  rules={[{ required: true, message: 'Veuillez choisir votre ville' }]}
                >
                  <Select
                    loading={zipOptionsFetching}
                    showSearch
                    disabled={zipOptionsFetching || !zipOptions?.length}
                    placeholder=""
                    filterOption={
                      (input, option) => option.children.toLowerCase().includes(
                        input.toLowerCase(),
                      )
                    }
                  >
                    {zipOptions?.map((option) => (
                      <Option key={`${option.oid}`} value={option.oid}>
                        {option.commune.nomMaj}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="form-row--wrapper">
                <Form.Item
                  name="pays"
                  label="Pays"
                  className="registration-form-field"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder=""
                    filterOption={
                      (input, option) => option.children.toLowerCase().includes(
                        input.toLowerCase(),
                      )
                    }
                  >
                    {countriesList.map((option) => (
                      <Option key={`${option.oid}`} value={option.oid} >
                        {option.libelle}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              
              
        
              
            </Form>
          </div>
        </div>
        <h1 className="checkout-form-title">03. Paiement</h1>
        <div className="choose-payment--wrapper">
          
          <div className="choose-payment" onClick={requestCBPayment} role="presentation">
            <h3>Payer par carte bancaire</h3>
            <img src={CardIcon} alt="" />
          </div>

          {!hidePaypal ? (
            <div className= "choose-payment" onClick={requestPaypalPayment} role="presentation">
              <h3>Payer par Paypal</h3>
              <img src={PaypalIcon} alt="" />
            </div>
          ) : null }
          { showChequeOption ? ( 
            <>
              <div className="choose-payment cadeau-option">
                <h3>Chèque cadeau</h3>
                <br/>
                <div className="cadeau-option--wrapper">
                  <p>Pour profiter de votre chèque cadeau, saisissez votre code : </p>
                  <div className="promo-section-input--wrapper">
                    <input className="promo-section-input" value={voucherValue} onChange={handleChangeVoucherValue} type="text" />
                    <CaliceoButton
                      title=""
                      primaryButton
                      onClick={submitVoucherValue}
                      classNames="confirm-promo--btn"
                      showIcon
                    />
                  </div>
                </div>
                <div className="liste_bon_cadeau">
                  {vouchers.map((un_code) => (
                    <div>
                    
                    <button
                      className="cart-product-remove--button"
                      onClick={() => removeVoucher(un_code)}
                      type="button"
                    >
                      <TrashIcon />
                    </button>
                    <p>{un_code}</p>
                    </div>
                  ))}  
                </div>
                {parseInt(props.cartData.totalTTC) === 0 ? (
                  <>
                    <br />
                    <CaliceoButton
                      title="Valider ma commande"
                      primaryButton
                      onClick={requestCBPayment}
                      classNames="primary-caliceo--button"
                    />
                  </>
                ) : null}
                
              </div>
              {canPayWithVoucher ? (
                <div className="choose-payment">
                  <h3>Payer par chèque cadeau</h3>
                </div>
              ) : null}
            </>
          ) : null }
          {/* {selectedCenter?.centerId != "centre-pau" ? (
            <>
              <div className="choose-payment" onClick={requestCBPayment} role="presentation">
                <h3>Payer par carte bancaire</h3>
                <img src={CardIcon} alt="" />
              </div>
            </>
          ) : null} */}
        </div>
      </div>
      {loadPaypal ? (
        <div className="loader-paymet">
          <div className="cont_load">
            <img src={load_gif} style={{width:50}} />
          </div>
          
        </div>
      ) : null}
    </div>
  );
};

export default PaymentSelection;
