import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { getAccountInfo } from 'redux/actions/auth';
import { selectUserInfo } from 'redux/selectors/auth';

import { selectCartData, selectGiftProducts, selectCoffretProducts } from 'redux/selectors/cart';
import { selectCenters } from 'redux/selectors/centers';



import {
  setCart
} from 'redux/actions/cart';

const mapStateToProps = (state) => ({
  cartData: selectCartData(state),
  centers: selectCenters(state),
  giftProducts: selectGiftProducts(state),
  coffretProducts: selectCoffretProducts(state),
  userInfo: selectUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setCart,
    getAccountInfo
  }, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps);
