import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import {
  Form, Select, Checkox, Input, Radio, DatePicker, Switch
} from 'antd';
import useWindowWidth from 'hooks/use-window-width';

const EBilletForm = (props) => {
  const { giftProduct, form, handDelivery } = props;
  const [showAdditionalMessage, setShowAdditionalMessage] = useState('Show');

  const [checked, setChecked] = useState(handDelivery);
  const [ formN ] = Form.useForm();

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  useEffect(() => {
    setChecked(handDelivery);
  }, [handDelivery]);

  useEffect(() => {
    formN.setFieldsValue({ handDelivery:checked });
  }, [checked]);

    
  return (
    <>
    {(windowWidth < 768) ? (
      <Form name={`${giftProduct.uid}`} form={formN} layout="vertical" initialValues={{ handDelivery: handDelivery }} className="gift-form">
      <div className="gift-form-row">
        <div className="gift-form-column">
          <Form.Item
            name="offreur"
            label="De la part de"
            validateTrigger={['onBlur', 'onSubmit']}
            rules={
            [
              { required: true, message: 'Ce champ est requis' },
            ]
            }
          >
          <Input />
            </Form.Item>
          <Form.Item
            name="lastName"
            label="Nom du bénéficiaire"
            validateTrigger="onSubmit"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="Prénom du bénéficiaire"
            validateTrigger="onSubmit"
          >
            <Input />
          </Form.Item>
          {!handDelivery ? (
            <Form.Item
              name="recipient_email"
              label="Email du bénéficiaire"
              validateTrigger="onSubmit"
              rules={
                  [
                    { required: true },
                    {
                      pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                      message: `L\'entrée n\'est pas un e-mail valide`,
                    },
                    
                  ]
                }
            >
              <Input />
            </Form.Item>
          ) : null}
          
        </div>
        <div className="gift-form-column">
          
          {!handDelivery ? (
            <Form.Item
              name="date"
              label="Date d'envoi"
              validateTrigger={['onBlur', 'onSubmit']}
            >
              <DatePicker style={{ width: '100%' }} locale={locale} format="DD/MM/YYYY" placeholder="Choisir une date" />
            </Form.Item>
          ) : null}
          
        </div>
      </div>
      <Form.Item>
        <p className="mt-4 titre_cadeau">Personnaliser votre cadeau :</p>
        <Form.Item>
          <Radio checked={showAdditionalMessage === 'Show'} value="Show" onClick={() => setShowAdditionalMessage('Show')}>Ajouter un message personnalisé</Radio>
          <Radio checked={showAdditionalMessage === 'Hide'} value="Hide" onClick={() => setShowAdditionalMessage('Hide')}>Non merci</Radio>
        </Form.Item>
        
          {showAdditionalMessage === 'Show' ? (
            <>
              <b>ATTENTION : les émoticônes ne sont pas acceptés et le nombre de caractères est limité à 255.</b>
              <Form.Item name="message">
                <Input.TextArea autoSize maxLength={255}  />
              </Form.Item>
            </>
          ) : null}
        
      </Form.Item>
      <Form.Item>
        <div className="change-pass-buttons--wrapper">
          <button id={`${giftProduct.uid}-submit`} type="submit" className="primary-caliceo--button d-none">
            Modifier
          </button>
        </div>
      </Form.Item>
     <Form.Item noStyle name="handDelivery" hidden className="d-none">
        <Switch
        checked={checked} onChange={setChecked}
        checkedChildren="On"
        unCheckedChildren="Off"
        className="d-none"
        />
    </Form.Item>
    </Form>
    ) : (
      <Form name={`${giftProduct.uid}`} form={formN} layout="vertical" initialValues={{ handDelivery: handDelivery }} className="gift-form">
      <div className="gift-form-row">
        <div className="gift-form-column">
          <Form.Item
            name="offreur"
            label="De la part de"
            validateTrigger={['onBlur', 'onSubmit']}
            rules={
            [
              { required: true, message: 'Ce champ est requis' },
            ]
            }
          >
          <Input />
            </Form.Item>
          <Form.Item
            name="lastName"
            label="Nom du bénéficiaire"
            validateTrigger="onSubmit"
          >
            <Input />
          </Form.Item>
          {!handDelivery ? (
            <Form.Item
              name="recipient_email"
              label="Email du bénéficiaire"
              validateTrigger="onSubmit"
              rules={
                  [
                    { required: true },
                    {
                      pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                      message: `L\'entrée n\'est pas un e-mail valide`,
                    },
                    
                  ]
                }
            >
              <Input />
            </Form.Item>
          ) : null}
          
        </div>
        <div className="gift-form-column">
          <div className="gift-form-empty" />
          <Form.Item
            name="firstName"
            label="Prénom du bénéficiaire"
            validateTrigger="onSubmit"
          >
            <Input />
          </Form.Item>
          {!handDelivery ? (
            <Form.Item
              name="date"
              label="Date d'envoi"
              validateTrigger={['onBlur', 'onSubmit']}
            >
              <DatePicker style={{ width: '100%' }} locale={locale} format="DD/MM/YYYY" placeholder="Choisir une date" />
            </Form.Item>
          ) : null}
          
        </div>
      </div>
      <Form.Item>
        <p className="mt-4 titre_cadeau">Personnaliser votre cadeau :</p>
        <Form.Item>
          <Radio checked={showAdditionalMessage === 'Show'} value="Show" onClick={() => setShowAdditionalMessage('Show')}>Ajouter un message personnalisé</Radio>
          <Radio checked={showAdditionalMessage === 'Hide'} value="Hide" onClick={() => setShowAdditionalMessage('Hide')}>Non merci</Radio>
        </Form.Item>
        
          {showAdditionalMessage === 'Show' ? (
            <>
              <b>ATTENTION : les émoticônes ne sont pas acceptés et le nombre de caractères est limité à 255.</b>
              <Form.Item name="message">
                <Input.TextArea autoSize maxLength={255}  />
              </Form.Item>
            </>
          ) : null}
        
      </Form.Item>
      <Form.Item>
        <div className="change-pass-buttons--wrapper">
          <button id={`${giftProduct.uid}-submit`} type="submit" className="primary-caliceo--button d-none">
            Modifier
          </button>
        </div>
      </Form.Item>
     <Form.Item noStyle name="handDelivery" hidden className="d-none">
        <Switch
        checked={checked} onChange={setChecked}
        checkedChildren="On"
        unCheckedChildren="Off"
        className="d-none"
        />
    </Form.Item>
    </Form>
    )}
  </>
  );

  
};

export default EBilletForm;
