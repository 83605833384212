import React, { useState, useEffect } from 'react';
import {
  Checkbox,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OptionEbillet from 'components/icon-components/OptionEbillet';
import OptionCoffret from 'components/icon-components/OptionCoffret';
import 'utils/font-awesome';

import CoffretForm from './CoffretForm';
import EBilletForm from './EBilletForm';

import IconImg from 'images/Option Ebillet.svg';

const GiftFormItem = (props) => {
  const {
    giftProduct,
    firstGiftItem,
    form,
    firstGiftId,
    coffretProducts,
    removeProductAsCoffret,
    setProductAsCoffret
  } = props;
  const [itemIsFirst, setItemIsFirst] = useState(false);
  const [useFirstItemData, setUseFirstItemData] = useState(false);
  const [deliverChoice, setDeliverChoice] = useState('ebillet');
  const [handDelivery, setHandDelivery] = useState(false);
  const [hideEbillet, setHideEbillet] = useState(false);


  useEffect(() => {
    if (firstGiftId === giftProduct.uid) {
      setItemIsFirst(true);
      setUseFirstItemData(false);
      if(giftProduct.envp) {
        setDeliverChoice("coffret");
      }
    }
  }, [giftProduct, firstGiftId]);

  /*useEffect(() => {
    if(location.hostname.includes("perpignan")) {
      setHideEbillet(true);
    }
  }, []);*/

  const handleChangeUseFirstItemData = (e) => {
    setUseFirstItemData(e.target.checked);
  };

  const handleChangeHandDelivery = (e) => {
    setHandDelivery(e.target.checked);
  };

  return (
    <div className="gift-form-wrapper gift-one-product">
      <div className="gift-offer-product--wrapper">
        <span className="gift-title-before-product">{giftProduct.title}</span>
      </div>
      <p className="mt-4 titre_cadeau">Configurer votre cadeau :</p>
      <Checkbox onChange={handleChangeHandDelivery} checked={handDelivery}>
        Je souhaite que le bon cadeau me soit envoyé directement pour le remettre personnellement.
      </Checkbox>
      {!itemIsFirst ? (
        <Checkbox onChange={handleChangeUseFirstItemData} checked={useFirstItemData}>
          Le destinataire est identique
        </Checkbox>
      ) : null}
      {!useFirstItemData ? (
        <>
          {!hideEbillet ? (
            <>

              <p className="mt-4 titre_cadeau">Sélectionner le format de votre choix :</p>
              
            </>
          ) : null}
          
          <div className="choose-option-wrapper">
            <div className="gift-option" role="presentation" onClick={() => {setDeliverChoice('ebillet');removeProductAsCoffret(giftProduct.uid)}}>
              <div className="gift-option-icon-wrapper">
                <div style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '50%',
                  background: `${deliverChoice === 'ebillet' ? '#8bacdb' : 'white'}`,
                  border: '1px solid #8bacdb',
                }}
                />
                <OptionEbillet />
              </div>
              <div className="gift-option-body">
                <p>E-billet</p>
                <p>Envoyé par email</p>
                <p>Gratuit</p>
              </div>
            </div>
            {!hideEbillet ? (
            <div className="gift-option" role="presentation" onClick={() => {setDeliverChoice('coffret');setProductAsCoffret(giftProduct.uid)}}>
              <div className="gift-option-icon-wrapper">
                <div style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '50%',
                  background: `${deliverChoice === 'coffret' ? '#8bacdb' : 'white'}`,
                  border: '1px solid #8bacdb',
                }}
                />
                <OptionCoffret />
              </div>
              <div className="gift-option-body">
                <p>Enveloppe cadeau</p>
                <p>Expédiée à domicile</p>
                <p>En lettre suivie</p>
                <p>Frais de gestion 3€ par article</p>
              </div>
            </div>
          ) : null}
            
              
          </div>
          {deliverChoice === 'ebillet' ? (
            <EBilletForm
              giftProduct={giftProduct}
              form={form}
              handDelivery={handDelivery}
            />
          ) : (
            <CoffretForm
              giftProduct={giftProduct}
              form={form}
              handDelivery={handDelivery}
            />
          )}
        </>
      ) : null}
      <hr />
    </div>
  );
};

export default GiftFormItem;
