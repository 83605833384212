import React from 'react';
import timeConvert from 'utils/time';
import { numberDisplayer } from 'utils/number';
import { fourthImage } from 'utils/dummyImages';

/**
 *
 * @param props
 * @param {{}} props.product
 * @return {*}
 * @constructor
 */
const CartDetailsProduct = (props) => {
  const { product } = props;

  return (
    <div className="single-product">
      <div className="single-product-image--wrapper">
        <img src={product.teaserImageUrl || fourthImage} alt={product.title} className="single-product-image" />
      </div>
      <div className="single-product-info--wrapper">
        <h3 className="single-product-title">
          {product.title} x{product.quantity}
        </h3>
        {product.duration && product.duration !== '0' && product.id != '430' && product.id != '431' && product.id != '573' && product.id != '340' && product.id != '648' && product.id != '647' && product.id != '677' && product.id != '841' && product.id != '839' && (
          <div className="single-product-info">
            <span className="single-product-info--key">
              Durée :
            </span>
            {' '}
            <span className="single-product-info--value">
              {timeConvert(product.duration)}
            </span>
          </div>
        )}
        <div className="single-product-info">
          <span className="single-product-info--key">
            Option cadeau :
          </span>
          {' '}
          <span className="single-product-info--value">
            {product.gift ? 'Oui' : 'Non'}
          </span>
        </div>
        <p className="single-product-price">{numberDisplayer(product.quantity * product.price)}€</p>
      </div>
    </div>
  );
};

export default CartDetailsProduct;
