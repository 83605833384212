import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setUserLoggedIn } from 'redux/actions/auth';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setUserLoggedIn,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
