import React from 'react';

const OptionCoffret = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    // style="enable-background:new 0 0 512 512;"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path d="M314.1,152.7v10.2V299H0v83.3c0,5.6,4.6,10.2,10.2,10.2H43c4.7,23.3,25.4,40.9,50.1,40.9s45.3-17.6,50.1-40.9h206.5v0
			c4.7,23.3,25.4,40.9,50.1,40.9s45.3-17.6,50.1-40.9h52.1c5.6,0,10.2-4.6,10.2-10.2v-99c0-3.6-1.9-7-5.1-8.8l-29.5-17.2l-16.9-72.1
			c-4.5-19.2-21.4-32.6-41.1-32.6h-84.9H314.1z M93,413.1c-16.9,0-30.7-13.8-30.7-30.7s13.8-30.7,30.7-30.7
			c16.9,0,30.7,13.8,30.7,30.7S109.9,413.1,93,413.1z M314.1,335.6h-92c-5.6,0-10.2,4.6-10.2,10.2s4.6,10.2,10.2,10.2h92v16.2h-171
			c-4.7-23.3-25.4-40.9-50.1-40.9S47.7,348.9,43,372.2H20.4v-52.8h293.7L314.1,335.6L314.1,335.6z M393.7,173.1h25.7
			c10.2,0,18.9,6.9,21.2,16.8l14.9,63.9h-61.9L393.7,173.1L393.7,173.1z M399.6,413.1c-16.2,0-29.5-12.7-30.6-28.6
			c0.1-0.7,0.2-1.3,0.2-2s-0.1-1.4-0.2-2c1.1-16,14.4-28.6,30.6-28.6c16.9,0,30.7,13.8,30.7,30.7S416.5,413.1,399.6,413.1z
			 M491.6,323.8h-19.4V313h19.4V323.8z M491.6,292.6H462c-5.6,0-10.2,4.6-10.2,10.2V334c0,5.6,4.6,10.2,10.2,10.2h29.6v28h-41.9
			c-4.7-23.3-25.4-40.9-50.1-40.9s-45.3,17.6-50.1,40.9h-15v-199h38.8v90.9c0,5.6,4.6,10.2,10.2,10.2h82.2l25.9,15L491.6,292.6
			L491.6,292.6z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M187.7,335.6h-8.6c-5.6,0-10.2,4.6-10.2,10.2s4.6,10.2,10.2,10.2h8.6c5.6,0,10.2-4.6,10.2-10.2S193.3,335.6,187.7,335.6z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M283.3,119H226c4.1-2.8,7.7-5.6,10.1-8.1c14.6-14.7,14.6-38.6,0-53.3c-14.2-14.3-39-14.3-53.2,0
			c-7.9,7.9-28.7,40-25.8,61.5h-1.3c2.9-21.5-18-53.6-25.8-61.5c-14.2-14.3-39-14.3-53.2,0c-14.6,14.7-14.6,38.6,0,53.3
			c2.5,2.5,6,5.3,10.1,8.1H29.5c-10,0-18.1,8.1-18.1,18.1v45.3c0,5,4.1,9.1,9.1,9.1h9.1v108.8c0,10,8.1,18.1,18.1,18.1h217.6
			c10,0,18.1-8.1,18.1-18.1V191.5h9.1c5,0,9.1-4.1,9.1-9.1v-45.3C301.5,127.1,293.3,119,283.3,119z M89.6,70.3
			c3.7-3.7,8.6-5.7,13.7-5.7c5.2,0,10,2,13.7,5.7c12.2,12.3,24.4,43.7,20.1,48.1c0,0-0.8,0.6-3.4,0.6c-12.5,0-36.4-13-44.3-20.9
			C82,90.4,82,77.9,89.6,70.3z M147.3,300.3H47.6V191.5h99.7V300.3z M147.3,173.4H29.5v-36.3h104.4h13.5V173.4z M195.7,70.3
			c7.4-7.4,20.1-7.4,27.5,0c7.6,7.7,7.6,20.1,0,27.8c-7.8,7.9-31.7,20.9-44.3,20.9c-2.6,0-3.4-0.6-3.4-0.6
			C171.4,114,183.5,82.6,195.7,70.3z M265.2,300.3h-99.7V191.5h99.7V300.3z M283.3,173.4H165.5v-36.3H179h104.4V173.4z"
        />
      </g>
    </g>
  </svg>

);

export default OptionCoffret;
