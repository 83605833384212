import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectCenters } from 'redux/selectors/centers';
import {
  selectIsPaymentSuccessful,
  selectPaymentInfo,
  selectPaymentIsProcessing,
} from 'redux/selectors/payment'
import { startPaymentStatus } from 'redux/actions/payment';

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
  paymentSuccessful: selectIsPaymentSuccessful(state),
  paymentData: selectPaymentInfo(state),
  paymentLoading: selectPaymentIsProcessing(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    startPaymentStatus,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
