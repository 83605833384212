import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import {notification } from 'antd';

import API from 'api';
import { centerCookieIds } from 'utils/constants';

const cookies = new Cookies();

const PaymentForm = (props) => {
  const { selectedCenter } = props;
  const [paymentForm, setPaymentForm] = useState(null);

  useEffect(() => {
    API.get(`/paiement/request?cid=${centerCookieIds[selectedCenter?.centerId].cid}&session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}&PaymentCode=${localStorage.getItem('webRef')}`).then((res) => {
      setPaymentForm(res.data);
      localStorage.setItem('webRef', res.headers['code-payment']);
    }).catch((error) => {
      //console.log({ error });
    });
  }, []);

  if (!paymentForm) {
    return null;
  }

  return (
    <div className="embed-responsive embed-responsive-1by1 payment-form--wrapper">
      {/* <div dangerouslySetInnerHTML={{ __html: paymentForm }} /> */}
      <iframe title="center-payment-frame" crossOrigin className="embed-responsive-item" src={`https://preprod.caliceo.com/api/paiement/request?cid=${centerCookieIds[selectedCenter?.centerId].cid}&session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}&PaymentCode=${localStorage.getItem('webRef')}`} />
    </div>
  );
};

export default PaymentForm;
