import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import API from 'api';
import Cookies from 'universal-cookie';

import CaliceoButton from 'components/buttons/CaliceoButton';
import CaliceoLink from 'components/buttons/CaliceoLink';

import { centerCookieIds } from 'utils/constants';

const cookies = new Cookies();

const SuccessOrder = (props) => {
  const { paymentData, selectedCenter } = props;
  const [orderTicket, setOrderTicket] = useState(null);

  useEffect(() => {
    if (paymentData?.orderId) {
      API.get(`/my-account/order-details/ticket?session=${cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName)}&id=${paymentData.orderId}}`, { responseType: 'blob', centerId: selectedCenter?.centerId }).then((res) => {
        setOrderTicket(res.data);
      });
    }
  }, [paymentData, selectedCenter]);

  const openTicket = (ticket) => {
    const file = new Blob(
      [ticket],
      { type: 'application/pdf' },
    );
    const fileURL = URL.createObjectURL(file);
    if (typeof window !== 'undefined') {
      window.open(fileURL);
    }
  };

  return (
    <div>
      <p className="order-status-thanks">Calicéo vous remercie.</p>
      <p className="order-status--order-number">Votre commande {paymentData.orderId} a bien éte effectuée</p>
      <p className="order-status-text">
        Vous recevrez prochainement un mail de confirmation de votre commande
        ainsi que les informations pratiques pour profiter de votre moment Calicéo.
      </p>
      {/* <CaliceoButton
        title="Têlécharger mon ebillet"
        onClick={() => openTicket(orderTicket)}
        primaryButton
        classNames="mx-auto"
      /> */}
      <div className="order-status-button--wrapper">
        <CaliceoLink
          title="Voir le détail de ma commande"
          url={`/mon-compte/commande/${paymentData.orderId}`}
          primaryButton
          classNames="mr-3"
        />
        <CaliceoButton
          title="Retour au site"
          onClick={() => navigate('/')}
          primaryButton
        />
      </div>
    </div>
  );
};

export default SuccessOrder;
