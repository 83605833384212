import React from 'react';

const OptionEbillet = (props) => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    // style="enable-background:new 0 0 512 512;"
    xmlSpace="preserve"
  >
    <g id="gift-hand-gift_card-celebration">
      <path d="M471.4,324.9l-40-96c-1.2-3-4.2-4.9-7.4-4.9h-24V72c0-2.1-0.2-56-0.2-56H344H120c-4.4,0-8,3.6-8,8v200H88
		c-3.2,0-6.1,1.9-7.4,4.9l-40,96c-0.9,2.1-0.8,4.6,0.2,6.7L71.1,392H56c-4.4,0-8,3.6-8,8v48c0,4.4,3.6,8,8,8v32c0,4.4,3.6,8,8,8h112
		c4.4,0,8-3.6,8-8v-32c4.4,0,8-3.6,8-8v-48c0-4.4-3.6-8-8-8h-18.2l-4.9-14.6L174,360h164l13.1,17.4l-4.9,14.6H328c-4.4,0-8,3.6-8,8
		v48c0,4.4,3.6,8,8,8v32c0,4.4,3.6,8,8,8h112c4.4,0,8-3.6,8-8v-32c4.4,0,8-3.6,8-8v-48c0-4.4-3.6-8-8-8h-15.1l30.3-60.4
		C472.2,329.5,472.3,327.1,471.4,324.9z M168,480H72v-24h96V480z M176,440H88v-16H72v16h-8v-32h112V440z M145.6,371.2
		c-1.6,2.1-2,4.8-1.2,7.3l4.5,13.5H88c0-1.2-0.3-2.5-0.8-3.6l-30.4-60.7L93.3,240H112v93.8l-0.4,0.3l-19.1-12.8l-8.9,13.3l24,16
		c2.9,1.9,6.7,1.8,9.4-0.4l33.6-26.8L166,344L145.6,371.2z M184,344c0-1.7-0.6-3.4-1.6-4.8l-24-32c-1.3-1.7-3.2-2.9-5.4-3.1
		c-2.1-0.3-4.3,0.4-6,1.7L128,321V32h256v48v241l-19-15.2c-1.7-1.3-3.9-2-6-1.7s-4.1,1.4-5.4,3.1l-24,32c-1,1.4-1.6,3.1-1.6,4.8
		L184,344z M440,480h-96v-24h96V480z M448,440h-8v-16h-16v16h-88v-32h112V440z M424.8,388.4c-0.5,1.1-0.8,2.3-0.8,3.6h-60.9
		l4.5-13.5c0.8-2.5,0.4-5.2-1.2-7.3L346,344l15.4-20.6l33.6,26.8c2.7,2.2,6.5,2.3,9.4,0.4l24-16l-8.9-13.3l-19.1,12.8l-0.4-0.3V240
		h18.7l36.5,87.7L424.8,388.4z"
      />
    </g>
    <g>
      <g>
        <g>
          <path d="M228.2,106.8H182V153h46.2V106.8z M219,143.8h-27.8V116H219V143.8z" />
          <rect x="200.5" y="125.2" width="9.2" height="9.2" />
          <path d="M283.8,106.8V153H330v-46.2H283.8z M320.8,143.8H293V116h27.8V143.8z" />
          <rect x="302.2" y="125.2" width="9.2" height="9.2" />
          <path d="M182,254.8h46.2v-46.2H182V254.8z M191.2,217.8H219v27.8h-27.8V217.8z" />
          <rect x="200.5" y="227" width="9.2" height="9.2" />
          <rect x="256" y="106.8" width="18.5" height="9.2" />
          <polygon points="256,143.8 265.2,143.8 265.2,134.5 274.5,134.5 274.5,125.2 246.8,125.2 246.8,116 237.5,116 237.5,125.2
				237.5,129.9 237.5,134.5 256,134.5 			"
          />
          <rect x="237.5" y="143.8" width="9.2" height="9.2" />
          <polygon points="274.5,153 265.2,153 265.2,162.2 237.5,162.2 237.5,171.5 274.5,171.5 			" />
          <polygon points="191.2,190 200.5,190 200.5,180.8 191.2,180.8 191.2,162.2 182,162.2 182,199.2 191.2,199.2 			" />
          <rect x="200.5" y="162.2" width="9.2" height="9.2" />
          <polygon points="237.5,199.2 256,199.2 256,190 246.8,190 246.8,180.8 228.2,180.8 228.2,162.2 219,162.2 219,180.8 209.8,180.8
				209.8,199.2 219,199.2 219,190 223.6,190 228.2,190 237.5,190 			"
          />
          <rect x="265.2" y="180.8" width="9.2" height="18.5" />
          <polygon points="265.2,208.5 237.5,208.5 237.5,217.8 256,217.8 256,245.5 237.5,245.5 237.5,254.8 265.2,254.8 265.2,245.5
				283.8,245.5 283.8,236.2 265.2,236.2 			"
          />
          <rect x="237.5" y="227" width="9.2" height="9.2" />
          <rect x="274.5" y="208.5" width="9.2" height="18.5" />
          <polygon points="320.8,227 293,227 293,254.8 302.2,254.8 302.2,236.2 320.8,236.2 			" />
          <rect x="311.5" y="245.5" width="18.5" height="9.2" />
          <rect x="320.8" y="208.5" width="9.2" height="9.2" />
          <polygon points="293,217.8 302.2,217.8 302.2,190 283.8,190 283.8,199.2 293,199.2 			" />
          <polygon points="311.5,171.5 302.2,171.5 302.2,162.2 293,162.2 293,171.5 283.8,171.5 283.8,180.8 320.8,180.8 320.8,171.5
				330,171.5 330,162.2 311.5,162.2"
          />
          <rect x="311.5" y="190" width="18.5" height="9.2" />
        </g>
      </g>
    </g>
  </svg>
);

export default OptionEbillet;
