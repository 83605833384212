import React from 'react';
import { Link } from 'gatsby';
import load_gif from '../../images/load.gif';

import SuccessOrder from './SuccessOrder';
import FailureOrder from './FailureOrder';
import './OrderStatus.scss';

const OrderStatus = (props) => {
  const {
    selectedCenter,
    paymentData,
    paymentSuccessful,
    paymentLoading,
  } = props;

  if (paymentLoading) {
    return (
      <div className="container">
        <div className={"loader loader_status"}>
          <img src={load_gif} style={{ width: 50 }} />
        </div>
      </div>
    );
  }

  if (localStorage.getItem('redirectToFront')) {
    localStorage.removeItem('redirectToFront');
    return (
      <div className="container">
        <p>Vous devez recharger la page</p>
      </div>
    );
  }

  return (
    <div className="container order-status">
      <Link to="/" className="order-center-name">
        {selectedCenter?.name}
      </Link>
      <h1 className="order-status-title">{paymentSuccessful ? 'Confirmation de votre commande' : 'Votre paiement a échoué'}</h1>
      {paymentSuccessful ? (
        <SuccessOrder paymentData={paymentData} selectedCenter={selectedCenter} />
      ) : (
        <FailureOrder />
      )}
    </div>
  );
};

export default OrderStatus;
